<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">设置</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>设置</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="py-30">
      <div class="mb-35 flex align-center cursorp" @click="adminpass">
        <div class="font-14 text-333333 mr-163">修改密码</div>
        <div class="text-CCCCCC">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="mb-35 flex align-center cursorp" @click="goserviceAgreement">
        <div class="font-14 text-333333 mr-163">服务协议</div>
        <div class="text-CCCCCC">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="mb-35 flex align-center cursorp" @click="gorichtext">
        <div class="font-14 text-333333 mr-163">版本详情</div>
        <div class="text-CCCCCC">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="mb-35 flex align-center cursorp" @click="outlogin">
        <div class="font-14 text-333333 mr-163">退出登录</div>
        <div class="text-CCCCCC">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local";
import router from "@/router";
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 退出登录
    outlogin() {
      local.clear();
      router.push("/login");
      setTimeout(() => {
        this.$router.go(0);
      }, 600);
    },
    // 修改密码
    adminpass() {
      this.$router.push({
        path: "/login",
        query: { type: "loginpass" },
      });
    },
    // 服务协议
    goserviceAgreement() {
      this.$router.push({
        path: "/serviceAgreement",
        query: {type: "serviceAgreement",},
      });
    },
    gorichtext() {
      this.$router.push({
        path: "/richtext",
        query: {
          type: "richtext",
          foottype: 9,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
}
</style>
